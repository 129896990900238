<template>
    <ModalBox
        :is-active.sync="isModalActive"
        :model="{ roleName }"
        @close="closeModal"
        @submit="updateRole">
        <template #header>
            <h5 class="title is-5">
                {{ isEdit ? $t("common.entity.role.action.edit.title") : $t("common.entity.role.action.create.title") }}
            </h5>
        </template>
        
        <RequiredField
            v-model.trim="roleName"
            :placeholder="$t('common.entity.role.action.create.labels.roleName')"
            prop="roleName">
        </RequiredField>
        <div class="column is-12">
            <div
                v-if="isActive"
                class="row-grid row-grid--checkbox-cover">
                <div
                    :key="index"
                    v-for="(permission, index) in permissions">
                    <CustomCheckbox
                        :value="selectedPermissions[permission.alias]"
                        :label="permission.alias"
                        class="is-small"
                        @input="selectPermission(permission.alias, $event)"></CustomCheckbox>
                </div>
            </div>
            <b-loading
                v-else
                :active="true"
                :is-full-page="false"></b-loading>
        </div>
        <template #footer>
            <div class="container">
                <div class="columns">
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            native-type="submit"
                            type="is-info is-light"
                            :loading="isRoleAdding">
                            {{ $t("common.buttons.save") }}
                        </b-button>
                    </div>
                    <div class="column">
                        <b-button
                            class="is-fullwidth"
                            type="is-light-button"
                            :loading="isRoleAdding"
                            @click="closeModal">
                            {{ $t("common.buttons.cancel") }}
                        </b-button>
                    </div>
                </div>
            </div>
        </template>
    </ModalBox>
</template>

<script>
  import CustomCheckbox from "@/components/Common/CustomCheckbox";
  import ModalBox from "@/components/Common/ModalBox";
  import { Modal } from "@core/mixins";
  import { ADD_ROLE, EDIT_ROLE, GET_PERMISSIONS, GET_ROLES } from "@core/store/action-constants";
  import { mapActions, mapState } from "vuex";
  import RequiredField from "@/components/Common/RequiredField";
  
  export default {
    name: "RolesNewRoleModal",
    mixins: [Modal],
    components: {
      RequiredField,
      CustomCheckbox,
      ModalBox
    },
    props: {
      role: {
        type: Object,
        default: null
      }
    },
    async created () {
      await this.getPermissions();
    },
    data () {
      return {
        roleName: null,
        selectedPermissions: {}
      };
    },
    computed: {
      ...mapState("admin", {
        permissions: ({ roles }) => roles.permissions?.items || []
      }),

      isEdit () {
        return this.role !== null;
      },

      isRoleAdding () {
        return this.$wait(`admin/${ ADD_ROLE }`);
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_PERMISSIONS,
        ADD_ROLE,
        EDIT_ROLE,
        GET_ROLES
      ]),
  
      selectPermission (alias, e) {
        this.selectedPermissions = { ...this.selectedPermissions, [alias]: e };
      },

      dataReset () {
        Object.assign(this.$data, this.$options.data());
      },

      async getPermissions () {
        await this.GET_PERMISSIONS();
      },

      async updateRole () {
        const permissions = Object.entries(this.selectedPermissions).map(item => {
          if (item[1]) {
            return item[0];
          } else return false;
        }).filter(item => item);

        try {
          if (this.isEdit) {
            await this.editRole(permissions);
          } else {
            await this.addRole(permissions);
          }
          this.dataReset();
          this.emitUpdate();
          this.closeModal();
        } catch {
          this.$buefy.toast.open({
            message: this.$t("common.messages.requestFail"),
            type: "is-danger"
          });
        }
      },

      async addRole (permissions) {
        try {
          await this.ADD_ROLE({
            name: this.roleName,
            permissions: permissions
          });

          this.GET_ROLES();

          this.$buefy.toast.open({
            message: this.$t("common.entity.role.action.create.messages.success"),
            type: "is-success"
          });
        } catch {
          this.$buefy.toast.open({
            message: this.$t("common.entity.role.action.create.messages.fail"),
            type: "is-danger"
          });
        }
      },

      async editRole (permissions) {
        const { id } = this.role;

        try {
          await this.EDIT_ROLE({
            roleId: id,
            params: {
              name: this.roleName,
              permissions: permissions
            }
          });

          this.$buefy.toast.open({
            message: this.$t("common.entity.role.action.edit.messages.success"),
            type: "is-success"
          });
        } catch {
          this.$buefy.toast.open({
            message: this.$t("common.entity.role.action.edit.messages.fail"),
            type: "is-danger"
          });
        }
      }
    },
    watch: {
      role: {
        deep: true,
        handler (value) {
          if (value) {
            this.roleName = this.role.name;
            Object.assign(this.selectedPermissions, this.role.permissions.reduce((acc, item) => {
              return {
                ...acc,
                [item]: true
              };
            }, {}));
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  ::v-deep {
    .modal-card {
      max-width: 800px;
      width: 100vw;
    }
  }

  .row-grid {
    &--checkbox-cover {
      grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
  }
</style>
