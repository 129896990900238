<template>
    <div class="wrapper">
        <RolesFilters></RolesFilters>
        <div class="column"></div>
        <RolesTable></RolesTable>
    </div>
</template>

<script>
  import RolesFilters from "@/components/Admin/Roles/RolesFilters";
  import RolesTable from "@/components/Admin/Roles/RolesTable";
  
  export default {
    name: "Roles",
    components: { RolesTable, RolesFilters }
  };
</script>

<style scoped>

</style>