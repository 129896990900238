<template>
    <TileItem :item="item">
        <template #content>
            &#8203;
        </template>
        <template #buttons>
            <Permissions :permissions="['ROLE.UPDATE']">
                <LTooltip
                    class="mr-3"
                    :label="$t(`admin.roles.table.buttons.editRole`)"
                    position="is-bottom"
                    type="is-warning">
                    <b-button
                        icon-left="pen"
                        size="is-small"
                        type="is-warning is-light"
                        @click.prevent.stop="edit">
                    </b-button>
                </LTooltip>
            </Permissions>
    
            <Permissions :permissions="['ROLE.DELETE']">
                <LTooltip
                    :label="$t(`admin.roles.table.buttons.removeRole`)"
                    position="is-bottom"
                    type="is-danger">
                    <b-button
                        icon-left="trash-alt"
                        icon-pack="far"
                        size="is-small"
                        type="is-danger is-light"
                        @click.prevent.stop="remove">
                    </b-button>
                </LTooltip>
            </Permissions>
        </template>
    </TileItem>
</template>

<script>
  import Permissions from "@/components/Common/Permissions";
  import TileItem from "@/components/Common/TileItem";
  
  export default {
    name: "RolesTableItem",
    components: { Permissions, TileItem },

    props: {
      item: {
        type: Object,
        required: true
      }
    },

    methods: {
      open () {
        this.$emit("open", this.item);
      },

      edit () {
        this.$emit("edit", this.item);
      },

      remove () {
        this.$emit("delete", this.item.id);
      }
    }
  };
</script>

<style lang="scss" scoped>
</style>
