<template>
    <div class="columns is-multiline">
        <Permissions :permissions="['ROLE.CREATE']">
            <div class="column has-text-right">
                <b-button
                    type="is-success is-light"
                    @click="isAddIntegrationModal = true">
                    {{ $t("admin.roles.filters.labels.createRole") }}
                </b-button>
            </div>
    
            <RolesNewRoleModal
                :is-active.sync="isAddIntegrationModal"
                @update="emitUpdate"></RolesNewRoleModal>
        </Permissions>
    </div>
</template>

<script>
  import RolesNewRoleModal from "@/components/Admin/Roles/RolesNewRoleModal";
  import Permissions from "@/components/Common/Permissions";
  import { Filters } from "@core/mixins";
  
  export default {
    name: "RolesFilters",
    mixins: [Filters],
    components: { Permissions, RolesNewRoleModal },
    data () {
      return {
        isAddIntegrationModal: false
      };
    }
  };
</script>

<style scoped>

</style>
