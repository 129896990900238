<template>
    <TablePlaceholder :data="items">
        <template slot-scope="{ items: _items }">
            <TilesTable
                :columns="4"
                :items="_items">
                <template slot-scope="{ item }">
                    <RolesTableItem
                        :item="item"
                        @delete="deleteRole($event)"
                        @edit="selectRole($event)">
                        <template #content>
                            &#8203;
                        </template>
                    </RolesTableItem>
                </template>
            </TilesTable>
            <RolesNewRoleModal
                :is-active.sync="editRoleModal"
                :role="selectedRole"
                @update="getRoles">
            </RolesNewRoleModal>
        </template>
        <template slot="placeholder">
            {{ $t(`common.table.noData`) }}
        </template>
    </TablePlaceholder>
</template>

<script>
  import RolesNewRoleModal from "@/components/Admin/Roles/RolesNewRoleModal";
  import RolesTableItem from "@/components/Admin/Roles/RolesTableItem";
  import TablePlaceholder from "@/components/Common/Table/TablePlaceholder";
  import TilesTable from "@/components/Common/TilesTable";
  import { DELETE_ROLE, GET_ROLES } from "@core/store/action-constants";
  import _cloneDeep from "lodash/cloneDeep";
  import { mapActions, mapState } from "vuex";
  
  export default {
    name: "RolesTable",
    components: {
      RolesNewRoleModal,
      RolesTableItem,
      TilesTable,
      TablePlaceholder
    },
    created () {
      this.getRoles();
    },
    data () {
      return {
        selectedRole: null,
        editRoleModal: false
      };
    },
    computed: {
      ...mapState("admin", {
        roles: ({ roles }) => _cloneDeep(roles.roles)
      }),

      items () {
        const items = _cloneDeep(this.roles);

        if (items && items.items) {
          items.items = items.items.map(role => {
            return {
              id: role.id,
              title: role.name,
              content: role.permissions
            };
          });
        }

        return items;
      }
    },
    methods: {
      ...mapActions("admin", [
        GET_ROLES,
        DELETE_ROLE
      ]),

      async getRoles () {
        await this.GET_ROLES();
      },

      deleteRole (roleId) {
        this.$buefy.dialog.confirm({
          title: this.$t("common.entity.role.action.delete.title"),
          message: this.$t("common.entity.role.action.delete.confirm"),
          confirmText: this.$t("common.buttons.delete"),
          cancelText: this.$t("common.buttons.cancel"),
          type: "is-danger is-light",
          onConfirm: async () => {
            try {
              await this.DELETE_ROLE(roleId);
              this.$buefy.toast.open({
                message: this.$t("common.entity.role.action.delete.messages.success"),
                type: "is-success"
              });
              await this.getRoles();
            } catch {
              this.$buefy.toast.open({
                message: this.$t("common.entity.role.action.delete.messages.fail"),
                type: "is-success"
              });
            }
          }
        });
      },

      selectRole (role) {
        if (!this.selectedRole && role) {
          this.selectedRole = this.roles?.items?.find(item => item.id === role.id);
          this.editRoleModal = true;
        } else {
          this.editRoleModal = false;
        }
      }
    },
    watch: {
      editRoleModal: {
        deep: true,
        handler (value) {
          if (!value) {
            this.selectedRole = null;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    .is-ancestor {
        margin-top: -1.5em;
    }

    .permissions {
        list-style: none;

        .permission {}
    }
</style>
